<template>
  <v-container fluid>
    <!-- <iframe :srcdoc="urlkibana" seamless="seamless" sandbox="allow-scripts"></iframe> -->
    <iframe name="iframeKibana" class="iframe-kb" :src="urlkibana" height="90%" width="100%"></iframe>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'

export default {
  name: 'Home',
  data: () => ({
    url: ''
  }),
  
  async mounted() {
    this.getUrlKibana().catch(e => errorSnackbar(e.error))
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('relatorioMoreira', ['urlkibana']),
  },

  methods: {
    ...mapActions('relatorioMoreira', ['getUrlKibana'])
  }
}
</script>

<style lang="scss" scoped>
  .iframe-kb {
    min-height: calc(100vh - 112px + 24px);
    border: none;
    margin: -24px;
    width: calc(100% + 50px);
  }
</style>